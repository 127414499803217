import { Action, createSlice } from "@reduxjs/toolkit"
import { getLanguageFromNavigator } from "src/utilities/implicit-bias-utils"

export enum ButtonState {
    Disabled = 0,
    Enabled = 1
}

export type Languages = 'en-US' | 'en-US'

export interface IUiState {
    courseStarted: boolean
    surveyStarted: boolean
    buttonState: ButtonState
    notifications: any[]
    language: Languages
}

const initialState: IUiState = {
    courseStarted: false,
    surveyStarted: false,
    buttonState: ButtonState.Enabled,
    notifications: [],
    language: 'en-US'
}

const uiSlice = createSlice({
    name: "@@ui",
    initialState,
    reducers: {
        updateLanguage(state, action) {
            state.language = action.payload
        },
        disableButton(state) {
            state.buttonState = ButtonState.Disabled;
        },
        enableButton(state) {
            state.buttonState = ButtonState.Enabled;
        },
        startCourse(state) {
            state.courseStarted = true;
        },
        resetHeaderVisibility(state) {
            state.surveyStarted = false;
            state.courseStarted = false;
        },
        startSurvey(state) {
            state.surveyStarted = true;
        },
      
        enqueueNotification(state, action) {
            
            state.notifications = [...state.notifications, {
                ...action.payload,
                key: action.payload.options.key
            }]
        },
        closeNotification(state, action) {
            state.notifications = state.notifications.map(notification => (
                (action.payload.dismissAll || notification.key === action.payload)
                    ? { ...notification, dismissed: true }
                    : { ...notification }
            ))
        },
        removeNotification(state, action) {
            state.notifications = state.notifications.filter(notification => notification.key !== action.payload)
        }
    },
    extraReducers: builder => { }
});

export const {
    enqueueNotification,
    closeNotification,
    removeNotification,
    startCourse,
    enableButton, disableButton,
    startSurvey, resetHeaderVisibility,
    updateLanguage
} = uiSlice.actions
export default uiSlice.reducer